import { ConfigData } from "api/models";

export const devINACTIVE: ConfigData = {
  serverUrl: "/api/",
  testBaseUrl: import.meta.env.BASE_URL,
  routerBasename: "/portal",
  noValPlaceholderCard: "-",
  placesAPICallDelay: 2000,
  clientContextId: false,
  scope: "NONE",
  logoutRedirectUrl: "mockLogoutUrl",
};
export const mock: ConfigData = {
  serverUrl: "/api/",
  testBaseUrl: import.meta.env.BASE_URL,
  routerBasename: "/portal",
  noValPlaceholderCard: "-",
  placesAPICallDelay: 2000,
  clientContextId: "zimdb-context",
  scope: "NONE",
  logoutRedirectUrl: "mockLogoutUrl",
};
export const baseConfig: ConfigData = {
  logoutRedirectUrl: "",
  serverUrl: "/api/",
  routerBasename: "/portal",
  noValPlaceholderCard: "-",
  clientContextId: false,
  scope: "NONE",
};
