import { RouteObject } from "react-router-dom";
import { toHandle, toNavItems } from "./route-helper";
import { MainLayout } from "~/layouts/MainLayout";
import { ErrorPage, Home } from "~/views";

export const ROUTE_INDEX = "/";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <MainLayout>
        <Home />
      </MainLayout>
    ),
    handle: toHandle("Portal", "StartPage"),
    errorElement: <ErrorPage />,
  },
];

export const navItems = toNavItems(routes);
