import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AxiosRequestConfig } from "axios";
import { createMessageFromAPIError } from "~/utils/ErrorUtil";
import { axiosApi } from "./AxiosClient";

type Args<T> = {
  url: string;
  method: AxiosRequestConfig<T>["method"];
  data?: AxiosRequestConfig<T>["data"];
  params?: AxiosRequestConfig<T>["params"];
};
export function axiosBaseQuery<T = unknown>(): BaseQueryFn<
  Args<T>,
  T,
  unknown
> {
  return async ({ url, method, data, params }, api) => {
    try {
      const response = await axiosApi.request({
        url,
        method,
        data,
        params,
        signal: api.signal,
      });
      return { data: response.data };
    } catch (error) {
      const errMsg = await createMessageFromAPIError(error);
      return { error: errMsg };
    }
  };
}
