import React from "react";
import { Container, Grid, styled } from "@mui/material";
import { Footer } from "frontend-commons-lib/src/components/Footer";
import { useKeycloak } from "~/api/KeycloakProvider";
import { useGlobalValue, useReduxGlobal } from "~/store";
import { NotificationSnackbar } from "frontend-commons-lib/src/components/NotificationSnackbar";
import { LoadingOverlay } from "frontend-commons-lib/src/components/LoadingOverlay";
import { TopBar } from "frontend-commons-lib/src/components/TopBar";
import { navItems } from "~/routes";
import { usePermissions } from "~/auth/Has";

const componentName = "mainlayout";

const footerHeight = 234;

const classes = {
  root: `${componentName}-root`,
  level1: `${componentName}-level1`,
  level2: `${componentName}-level2`,
};

const FullScreenLayoutStyles = styled("div")(({ theme }) => {
  return {
    [`& .${classes.root}`]: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      paddingTop: 50,
    },
    [`& .${classes.level1}`]: {
      flex: "1 0 auto",
      overflowY: "auto",
      overflowX: "hidden",

      background: theme.palette.extendedColors.background.default,
    },
    [`& .${classes.level2}`]: {
      flex: "1 100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      minHeight: `calc(100vh - ${footerHeight}px)`,

      paddingBottom: theme.spacing(2),
    },
  };
});

interface FullScreenLayoutProps {
  children: React.ReactNode;
}

export const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { keycloak } = useKeycloak();
  const config = useGlobalValue("config");
  const [isLoading, setIsloading] = useReduxGlobal("isLoading");
  const [notification, setNotification] = useReduxGlobal("notification");
  const hasPermissions = usePermissions();

  return (
    <FullScreenLayoutStyles>
      <LoadingOverlay isLoading={isLoading} setIsLoading={setIsloading} />
      <NotificationSnackbar
        notification={notification}
        setNotification={setNotification}
      />
      <section className={classes.root}>
        <TopBar
          handleLogout={() =>
            keycloak?.logout({ redirectUri: config?.logoutRedirectUrl })
          }
          navItems={navItems}
          permissions={hasPermissions}
        />
        <Grid container direction="column" wrap="nowrap">
          <section className={classes.level1}>
            <Container
              className={classes.level2}
              disableGutters={false}
              maxWidth="xl"
            >
              {children}
            </Container>
          </section>
        </Grid>
        <Footer />
      </section>
    </FullScreenLayoutStyles>
  );
};
