import type Keycloak from "keycloak-js";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { keycloak } from "./keycloak";
import { Loading } from "frontend-commons-lib/src/components/Loading";
import { useGlobalValueSetter } from "~/store";

interface Context {
  keycloak: Keycloak;
  initialized: boolean;
}

const KeycloakContext = React.createContext<Context>({
  keycloak: keycloak!,
  initialized: false,
});

export function useKeycloak(): Context {
  return useContext(KeycloakContext);
}

export const KeycloakProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const setisloading = useGlobalValueSetter("isLoading");
  const [initialized, setInitialized] = useState(false);
  const keycloakContextValue = useMemo(() => {
    return { keycloak: keycloak!, initialized };
  }, [initialized]);

  useEffect(() => {
    keycloak!.onReady = () => setInitialized(true);
    if (keycloak!.tokenParsed) {
      setInitialized(true);
    }
    return () => {
      keycloak!.onReady = undefined;
    };
  }, []);

  if (!initialized) {
    return <Loading setIsLoading={setisloading} />;
  }

  return (
    <KeycloakContext.Provider value={keycloakContextValue}>
      {children}
    </KeycloakContext.Provider>
  );
};

export const MockKeycloakProvider: React.FC<
  React.PropsWithChildren<{
    authClient: Keycloak;
  }>
> = ({ children, authClient }) => {
  const mockKeycloakContextValue = useMemo(() => {
    return { keycloak: authClient, initialized: true };
  }, [authClient]);

  return (
    <KeycloakContext.Provider value={mockKeycloakContextValue}>
      {children}
    </KeycloakContext.Provider>
  );
};
