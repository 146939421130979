import Keycloak from "keycloak-js";

function createKeycloak() {
  // https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
  const keycloak = new Keycloak("/portal/keycloak.json");
  keycloak.init({
    checkLoginIframe: false,
    pkceMethod: "S256",
    onLoad: "login-required",
    responseMode: "fragment",
  });
  return keycloak;
}

export const keycloak =
  import.meta.env.MODE === "test" || import.meta.env.VITE_STORYBOOK === "true"
    ? undefined
    : createKeycloak();
