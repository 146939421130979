import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { KeycloakProvider } from "./api/KeycloakProvider";
import { store } from "./store/RootState";
import { Provider } from "react-redux";

import { routes } from "./routes";
import theme from "./theme";

export const router = createBrowserRouter(routes, {
  basename: import.meta.env.BASE_URL, // "/portal"
});
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <KeycloakProvider>
          <RouterProvider router={router} />
        </KeycloakProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
