import { createSvgIcon } from "@mui/material";

export const AcIcon = createSvgIcon(
  <svg
    width="125"
    height="125"
    viewBox="0 0 125 125"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.533 70.708C0.183 68.025 0 65.283 0 62.5C0 27.979 27.983 0 62.5 0C94.121 0 120.258 23.483 124.421 53.963L54.167 29.167V58.333L0.533 70.708Z" />
    <path d="M2.67102 80.633C4.97902 88.254 8.70002 95.258 13.533 101.342L54.167 91.667V77.083L118.75 87.5L119.475 88.225C120.838 85.221 121.967 82.088 122.842 78.85L54.167 68.75L2.67102 80.633Z" />
    <path d="M95.783 115.413L54.167 110.417V124.45C41.796 122.8 30.571 117.533 21.588 109.75L54.167 102.083L105.138 108.2C102.258 110.883 99.129 113.304 95.783 115.413Z" />
  </svg>,
  "Abfrageclient"
);
export const MsIcon = createSvgIcon(
  <svg
    width="125"
    height="125"
    viewBox="0 0 125 125"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.533 70.708C0.183 68.025 0 65.283 0 62.5C0 27.979 27.983 0 62.5 0C94.121 0 120.258 23.483 124.421 53.963L54.167 29.167V58.333L0.533 70.708Z" />
    <path d="M2.67102 80.633C4.97902 88.254 8.70002 95.258 13.533 101.342L54.167 91.667V77.083L118.75 87.5L119.475 88.225C120.838 85.221 121.967 82.088 122.842 78.85L54.167 68.75L2.67102 80.633Z" />
    <path d="M95.783 115.413L54.167 110.417V124.45C41.796 122.8 30.571 117.533 21.588 109.75L54.167 102.083L105.138 108.2C102.258 110.883 99.129 113.304 95.783 115.413Z" />
  </svg>,
  "Marktschwankung"
);
export const AblagesystemIcon = createSvgIcon(
  <svg
    width="125"
    height="125"
    viewBox="0 0 125 125"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.533 70.708C0.183 68.025 0 65.283 0 62.5C0 27.979 27.983 0 62.5 0C94.121 0 120.258 23.483 124.421 53.963L54.167 29.167V58.333L0.533 70.708Z" />
    <path d="M2.67102 80.633C4.97902 88.254 8.70002 95.258 13.533 101.342L54.167 91.667V77.083L118.75 87.5L119.475 88.225C120.838 85.221 121.967 82.088 122.842 78.85L54.167 68.75L2.67102 80.633Z" />
    <path d="M95.783 115.413L54.167 110.417V124.45C41.796 122.8 30.571 117.533 21.588 109.75L54.167 102.083L105.138 108.2C102.258 110.883 99.129 113.304 95.783 115.413Z" />
  </svg>,
  "Ablagesystem"
);
export const QsIcon = createSvgIcon(
  <svg
    width="125"
    height="125"
    viewBox="0 0 125 125"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.533 70.708C0.183 68.025 0 65.283 0 62.5C0 27.979 27.983 0 62.5 0C94.121 0 120.258 23.483 124.421 53.963L54.167 29.167V58.333L0.533 70.708Z" />
    <path d="M2.67102 80.633C4.97902 88.254 8.70002 95.258 13.533 101.342L54.167 91.667V77.083L118.75 87.5L119.475 88.225C120.838 85.221 121.967 82.088 122.842 78.85L54.167 68.75L2.67102 80.633Z" />
    <path d="M95.783 115.413L54.167 110.417V124.45C41.796 122.8 30.571 117.533 21.588 109.75L54.167 102.083L105.138 108.2C102.258 110.883 99.129 113.304 95.783 115.413Z" />
  </svg>,
  "Qualitätsreport"
);
export const VgwIcon = createSvgIcon(
  <svg
    width="125"
    height="125"
    viewBox="0 0 125 125"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.533 70.708C0.183 68.025 0 65.283 0 62.5C0 27.979 27.983 0 62.5 0C94.121 0 120.258 23.483 124.421 53.963L54.167 29.167V58.333L0.533 70.708Z" />
    <path d="M2.67102 80.633C4.97902 88.254 8.70002 95.258 13.533 101.342L54.167 91.667V77.083L118.75 87.5L119.475 88.225C120.838 85.221 121.967 82.088 122.842 78.85L54.167 68.75L2.67102 80.633Z" />
    <path d="M95.783 115.413L54.167 110.417V124.45C41.796 122.8 30.571 117.533 21.588 109.75L54.167 102.083L105.138 108.2C102.258 110.883 99.129 113.304 95.783 115.413Z" />
  </svg>,
  "Vergleichswert"
);

export const LockIcon2 = createSvgIcon(
  <svg
    viewBox="0 0 39 39"
    width="39"
    height="39"
    role="img"
    data-testid="img"
    fill="#004E9E"
  >
    <g transform="matrix(1,0,0,1,-311.25,-686.728)">
      <g transform="matrix(0.689365,0,0,0.689365,-750.372,-71.5739)">
        <path
          d="M1552,1110L1546,1110L1546,1107C1546,1105.2 1547.2,1103 1549,1103C1550.8,1103 1552,1105.2 1552,1107L1552,1110ZM1551,1121L1547,1121L1548,1117C1547.4,1116.64 1547,1116.02 1547,1115C1547,1113.87 1547.88,1113 1549,1113C1550.13,1113 1551,1113.87 1551,1115C1551,1116 1550.6,1116.65 1550,1117L1551,1121ZM1556,1110L1556,1107C1556,1103.39 1552.61,1100 1549,1100C1545.39,1100 1542,1103.39 1542,1107L1542,1110L1540,1110L1540,1124L1558,1124L1558,1110L1556,1110Z"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>,
  "Gesperrt"
);
