import { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import type keycloak from "keycloak-js";

/**
 * Creates a request interceptor that uses the given keycloak instance
 * and tries to refresh the zbv session if token is about to expire in 5 sec
 * or already expired before the actual request is made.
 *
 * @param keycloak the current keycloak instance
 */

export const createKeycloakAwareRequestInterceptor =
  (keycloak: keycloak /* NOSONAR */) =>
  async (request: AxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
      await keycloak.updateToken(5);
      request.headers = request.headers ?? {};
      request.headers.Authorization = `Bearer ${keycloak.token}`;
    } catch {
      console.warn("ZBV session timed out.");
    }
    return request as InternalAxiosRequestConfig;
  };
