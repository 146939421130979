import { Permission } from "./permissions";

const defaultPermissions: Permission[] = ["StartPage", "NotFound"];

export type RoleBasedAccessControl = { [ROLE: string]: Permission[] };

// for more information: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
export const rules: RoleBasedAccessControl = {
  ROLE_USER: defaultPermissions,
};
