import React, { useMemo } from "react";
import { useKeycloak } from "~/api/KeycloakProvider";
import { rules } from "~/auth/rules";
import type Keycloak from "keycloak-js";
import { Permission } from "~/auth";
import { RoleBasedAccessControl } from "../RoleBasedAccessControl";

export function useHasPermission(permission: Permission | undefined) {
  const permissions = usePermissions();
  return permission ? permissions.includes(permission) : true; // No permission was required by the page, the page may be accessed by any logged-in user
}

export function usePermissions() {
  const { keycloak } = useKeycloak();
  return useMemo(() => toPermissions(keycloak, rules), [keycloak]);
}

function getRoles(keycloak: Keycloak): string[] {
  const clientId = keycloak?.clientId ?? "";
  const resourceAccess = keycloak?.tokenParsed?.resource_access;
  return resourceAccess?.[clientId]?.roles ?? [];
}

function toPermissions(
  keycloak: Keycloak,
  rules: RoleBasedAccessControl,
): Permission[] {
  const roles = getRoles(keycloak);
  const permissions = roles.reduce<Permission[]>((prev, curr) => {
    if (rules[curr] === undefined) {
      return prev;
    }
    const morePermissions = rules[curr].filter(
      (act) => prev.find((ac) => ac === act) === undefined,
    );
    return [...prev, ...morePermissions];
  }, []);
  return permissions;
}

interface Props {
  permission: Permission | undefined;
  Yes?: React.ReactElement | null;
  No?: React.ReactElement | null;
}
export const Has: React.ForwardRefRenderFunction<React.ComponentType, Props> = (
  { permission, Yes = null, No = null },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _ref,
) => {
  const allowed = useHasPermission(permission);
  return allowed ? Yes : No;
};
