import axios, { AxiosError } from "axios";

export interface ApiError {
  message: string;
}

export const createMessageFromAPIError = async (
  error: unknown
): Promise<string> => {
  if (!error) {
    return Promise.resolve("Anfrage fehlgeschlagen.");
  }
  let errorMessage;
  if (Array.isArray(error)) {
    errorMessage = "Validierung der Serverantwort fehlgeschlagen.";
    logError(error);
  } else if (axios.isAxiosError(error)) {
    try {
      const responseData =
        error.request.responseType === "arraybuffer" &&
        error.response!.data instanceof ArrayBuffer
          ? JSON.parse(Buffer.from(error.response!.data).toString("utf8"))
          : error.response!.data;

      errorMessage = getErrorMessage(error, responseData);
    } catch {
      // JSON parsing error
      errorMessage = "Serverantwort konnte nicht gelesen werden.";
    }
  }
  return Promise.resolve(
    errorMessage
      ? `Anfrage fehlgeschlagen: ${errorMessage}`
      : "Anfrage fehlgeschlagen."
  );
};

function getErrorMessage(error: AxiosError, responseData): string {
  let errorMessage: string = "";

  if (responseData) {
    try {
      const apiError = responseData as ApiError;
      errorMessage = apiError.message;
      logWarn(apiError);
    } catch (e) {
      logError(e);
    }
  } else {
    errorMessage = error?.message ?? "Ohne Grund.";
    logWarn(error);
  }

  return errorMessage;
}

function logError(msg: unknown) {
  if (import.meta.env.MODE !== "test") {
    console.error(msg);
  }
}

function logWarn(msg: unknown) {
  if (import.meta.env.MODE !== "test") {
    console.warn(msg);
  }
}
