import axios from "axios";

import { createKeycloakAwareRequestInterceptor } from "./interceptors/KeycloakAwareRequestInterceptor";
import { xsrfRequestInterceptor } from "./interceptors/XsrfRequestInterceptor";
import { keycloak } from "./keycloak";

export const axiosApi = axios.create({ baseURL: import.meta.env.BASE_URL });

if (keycloak) {
  const keycloakInterceptor = createKeycloakAwareRequestInterceptor(keycloak);
  axiosApi.interceptors.request.use(keycloakInterceptor);
  axiosApi.interceptors.request.use(xsrfRequestInterceptor);
}
