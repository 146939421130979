export const XsrfUtil = (function () {
  const generateToken = () => {
    const crypto = window.crypto; // for IE 11
    const hashArray = Array.from(crypto.getRandomValues(new Uint8Array(32)));
    return bytesToHex(hashArray);
  };

  const generateSetCookie = (token: string) => {
    let cookie = "XSRF-TOKEN=";
    cookie += token;
    cookie += "; Path=";
    cookie += import.meta.env.BASE_URL;
    cookie += "; SameSite=Lax; Max-Age=3"; // Should be Strict instead of Lax, but Firefox has a bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1627653
    if (window.location.protocol.startsWith("https")) cookie += "; Secure";
    return cookie;
  };

  const bytesToHex = (bytes: number[]) => {
    return bytes.map((byte) => ("00" + byte.toString(16)).slice(-2)).join("");
  };

  return {
    generateToken,
    generateSetCookie,
    bytesToHex,
  };
})();
