import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { portalApi } from "~/api";
import { AppContext, ConfigData, NotificationType } from "~/api/models";

export interface GlobalState {
  appContext: AppContext; // <== UserInformation from JWT-token

  // meta-data & configuration
  config: ConfigData | null;

  // state for visualizing a backend call.
  isLoading: boolean;

  notification: NotificationType;
  isLoggedOut: boolean;
  appConfig: unknown;
}

type SetValuePayload = {
  key: keyof GlobalState;
  value: GlobalState[keyof GlobalState];
};

export const initialState: GlobalState = {
  config: null,
  appConfig: null,
  appContext: {
    blz: "",
    bvnr: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
    organisationId: "",
    organisationName: "",
    phoneNumber: "",
    roleNames: [],
    salutation: "",
  },

  notification: { message: "", variant: "info" },
  isLoggedOut: false,

  isLoading: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setValue(state, { payload }: PayloadAction<SetValuePayload>) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state as any)[payload.key] = payload.value;
    },

    resetFilter(state) {
      console.log("state", state);
      // const searchStatus = {
      //   status: 0,
      //   mapInteraction: false,
      //   code: 0,
      //   info: "",
      // };
    },
  },
  extraReducers(builder) {
    const {
      initialize,
      // add your endpoint here,
    } = portalApi.endpoints;

    builder.addMatcher(initialize.matchFulfilled, (state, { payload }) => {
      Object.assign(state, payload);
    });
    builder.addMatcher(initialize.matchRejected, (state, { payload }) => {
      state.notification = {
        message: `${payload}`,
        variant: "error",
      };
    });
  },
});

export const { setValue, resetFilter } = globalSlice.actions;
export const globalReducer = globalSlice.reducer;
