import React, { Suspense } from "react";
import { UIMatch, useMatches } from "react-router-dom";
import { useInitializeQuery } from "~/api/PortalClientApi";
import { Permission } from "~/auth";
import { usePermissions } from "~/auth/Has";
import { NoMatch } from "~/views/NoMatch";
import { FullScreenLayout } from "./FullScreenLayout";
import { Loading } from "frontend-commons-lib/src/components/Loading";
import { useGlobalValueSetter } from "~/store";

interface MainLayoutProps {
  children: React.ReactElement;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const setIsloading = useGlobalValueSetter("isLoading");
  const hasPermissions = usePermissions();
  const matches = useMatches();
  const neededPermissions: Permission[] = matches
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((m: UIMatch<unknown, unknown>) => m.handle?.["hasPermission"])
    .filter((p) => !!p);
  const allowed = neededPermissions.every((needed) =>
    hasPermissions.includes(needed)
  );

  const { data } = useInitializeQuery();
  return data ? (
    <FullScreenLayout>
      <Suspense>{allowed ? children : <NoMatch />}</Suspense>
    </FullScreenLayout>
  ) : (
    <Loading setIsLoading={setIsloading} />
  );
};
