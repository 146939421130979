import React from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

const componentName = "errorPage";

const classes = {
  button: `${componentName}-button`,
};

const ErrorPageStyles = styled("div")(({ theme }) => {
  return {
    [`& .${classes.button}`]: {
      marginTop: theme.spacing(2),
    },
  };
});

export const ErrorPage: React.FC = () => {
  return (
    <ErrorPageStyles>
      <Grid container>
        <Grid item xs>
          <Card variant="outlined">
            <CardContent>
              <Grid container direction="column" alignItems="center">
                <Typography variant="h1" align="center" component="h1">
                  Ein Fehler ist aufgetreten
                </Typography>
                <Typography align="center" gutterBottom>
                  Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es
                  erneut, wenn dieser Fehler wieder auftritt kontaktieren Sie
                  den Support.
                </Typography>
                <Button
                  variant="contained"
                  component={Link}
                  to={"/"}
                  color="secondary"
                  className={classes.button}
                >
                  Zurück zur Anwendung
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ErrorPageStyles>
  );
};
