import { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { XsrfUtil } from "~/utils/XsrfUtil";

const ALLOWED_METHODS = ["GET", "HEAD", "TRACE", "OPTIONS"];

/**
 * A request interceptor that adds a short living xsrf token cookie
 * if a request is made where the method is not one of GET, HEAD, TRACE, OPTIONS.
 *
 * @param request the axios request.
 */

export const xsrfRequestInterceptor = (
  request: AxiosRequestConfig,
): InternalAxiosRequestConfig => {
  if (
    !request.method ||
    !ALLOWED_METHODS.includes(request.method.toUpperCase())
  ) {
    window.document.cookie = XsrfUtil.generateSetCookie(
      XsrfUtil.generateToken(),
    );
  }
  return request as InternalAxiosRequestConfig;
};
