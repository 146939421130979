import { OpenInNew } from "@mui/icons-material";
import { Grid, Typography, styled } from "@mui/material";
import React from "react";
import { useKeycloak } from "~/api/KeycloakProvider";
import { ContentBox } from "frontend-commons-lib/src/components/ContentBox";
import {
  AblagesystemIcon,
  AcIcon,
  MsIcon,
  QsIcon,
  VgwIcon,
} from "~/components/icons/ModuleIcons";
import { Haus } from "~/theme/assets/Icons";

const componentName = "breadcrumb";

const classes = {
  icon: `${componentName}-icon`,
  text: `${componentName}-text`,
  introtextContainer: `${componentName}-introtextContainer`,
};

const HomeStyles = styled(Grid)(({ theme }) => {
  return {
    [`& .${classes.introtextContainer}`]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: theme.spacing(2),
    },
    [`& .${classes.icon}`]: {
      marginRight: theme.spacing(0.5),
      height: 13,
      width: 13,
    },
    [`& .${classes.text}`]: {
      marginTop: 5,
    },
  };
});

export const Home: React.FC = () => {
  const { keycloak } = useKeycloak();
  const resourceAccess = keycloak?.tokenParsed?.resource_access ?? [];

  const hasObjectWithPrefix = (prefix: string): boolean => {
    return Object.keys(resourceAccess).some((key) => key.startsWith(prefix));
  };

  const greeting = keycloak?.tokenParsed?.name
    ? `Guten Tag, ${keycloak.tokenParsed.name}`
    : "Guten Tag";

  return (
    <HomeStyles container spacing={2}>
      <Grid item xs={12} md={12}>
        <div className={classes.text}>
          <Haus className={classes.icon} />
          Startseite ZIMDB
        </div>
      </Grid>
      <Grid item xs={12} md={12}>
        <ContentBox header={{ headline: greeting }}>
          <div className={classes.introtextContainer}>
            <Typography>
              Die Zentrale Immobilienmarkt-Datenbank (ZIMDB) ist das Portal für
              die Immobilienanalyse. Sie vernetzt Daten aus den Ankerprodukten
              rund um die Immobilie von Sparkassen, Landesbanken und
              Landesbausparkassen. Die Anwendungen der ZIMDB unterstützen Sie in
              zentralen Aufgaben: Sie sind wichtige Instrumente in der
              Bearbeitung regulatorischer Aufgaben und der Digitalisierung im
              Immobilienprozess.
            </Typography>
            <Typography>
              Über diese zentrale Portalseite erreichen Sie alle Module, die in
              den nächsten Monaten sukzessive veröffentlicht und erweitert
              werden.
            </Typography>
          </div>
        </ContentBox>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ContentBox
              header={{ headline: "Marktschwankung", icon: <MsIcon /> }}
              isEnabled={hasObjectWithPrefix("zimdbmsk")}
              button={{
                text: "Marktschwankung starten",
                url: window.location.origin + "/msk",
              }}
            >
              <Typography>
                Fragen Sie die jährlich ermittelten Marktschwankungen für
                verschiedene Objektarten der letzten ein, zwei und drei Jahre
                ab. Die Abfragen sind für einzelne Postleitzahlen oder eine
                Vielzahl von Postleitzahlen und Objektarten möglich. Die
                Ergebnisse können Sie direkt in den Marktschwankungsbericht
                übernehmen. Perspektivisch erhalten Sie Indikatoren für die
                Neubewertung von Objekten.
              </Typography>
            </ContentBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentBox
              header={{ headline: "Abfrageclient", icon: <AcIcon /> }}
              isEnabled={hasObjectWithPrefix("zimdbac")}
              button={{
                text: "Abfrageclient starten",
                url: window.location.origin + "/ac",
              }}
            >
              <Typography>
                Der Abfageclient dient der gezielten Suche im eigenen oder
                mandantenübergreifenden Immobilienbestand. Mehrere Suchtechniken
                und Filterungen ermöglichen eine flexible Ergebnisdarstellung
                als Listen- und Kartenansicht. Detaillierte Gutachtenansichten,
                individuelle Datenauswertungen und grafisch visualisierte
                Marktinformationen fördern die spezifische Weiterbearbeitung.
              </Typography>
            </ContentBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ContentBox
              header={{
                headline: "Ablagesystem",
                icon: <AblagesystemIcon />,
              }}
              isEnabled={false}
              button={{ text: "Ablagesystem starten", url: "" }}
            >
              <Typography>
                Das Ablagesystem unterstützt Finanzierungsberater,
                Marktfolgemitarbeiter und Gutachter bei der
                Dokumentenbeschaffung und -verarbeitung im Finanzierungsprozess.
                Intelligentes Auslesen mit moderner KI-Technologie erleichtert
                die Beschaffung relevanter Informationen. Im Stammbuch, einem
                geschützten Bereich, können Kunden Dokumente rund um ihre
                Immobilien verwalten.
              </Typography>
            </ContentBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentBox
              header={{
                headline: "Qualitätsreport",
                icon: <QsIcon />,
              }}
              isEnabled={hasObjectWithPrefix("zimdbdq")}
              button={{
                text: "Qualitätsreport starten",
                url: window.location.origin + "/dq",
              }}
            >
              <Typography>
                Die regelmäßig veröffentlichten Reports beleuchten
                unterschiedliche Objekt- und Gutachtendaten und schaffen
                Transparenz zur Datenqualität. Sie gewinnen wichtige
                Informationen zur Qualitätsverbesserung und Hinweise zur
                Optimierung Ihrer Prozesse. Nutzen Sie die integrierte und
                laufend aktualisierte Datenfibel als Leitfaden für die
                Datenpflege.
              </Typography>
            </ContentBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <ContentBox
              header={{
                headline: "Vergleichswert",
                icon: <VgwIcon />,
              }}
              isEnabled={hasObjectWithPrefix("zimdbvw")}
              button={{
                text: "Vergleichswert starten",
                url: window.location.origin + "/vw",
              }}
            >
              <Typography>
                Die Anwendung Vergleichswert unterstützt bei der Lagebeurteilung
                von Wohnimmobilien und bietet die Möglichkeit,
                Vergleichswertvorschläge für die Gutachtenerstellung zu
                ermitteln. Dabei ist neben der automatisierten Ermittlung von
                passenden Vergleichsobjekten auch die Vergleichswertermittlung
                auf Basis statistischer Verfahren möglich.
              </Typography>
            </ContentBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContentBox
              header={{ headline: "Informationen" }}
              button={{
                text: "Infocenter starten",
                url: "https://portal.dsv-gruppe.de/apps/zimdbinfocenter/zimdb-infocenter.html",
                target: "_blank",
                variant: "outlined",
                color: "primary",
                icon: <OpenInNew />,
              }}
            >
              <Typography>
                Nutzen Sie ergänzende Informationen wie Anleitungen,
                Musterdateien und weitere Hilfsdokumente zur Unterstützung Ihrer
                täglichen Arbeit.
              </Typography>
            </ContentBox>
          </Grid>
        </Grid>
      </Grid>
    </HomeStyles>
  );
};
