import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import { fonts } from "frontend-commons-lib/theme/typography";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTE_INDEX } from "~/routes";
import { ChevronCircle } from "~/theme/assets/Icons";

const componentName = "noMatch";

const classes = {
  headline: `${componentName}-headline`,
  subline: `${componentName}-subline`,
};

const NoMatchStyles = styled("div")(() => {
  return {
    [`& .${classes.headline}`]: {
      fontFamily: fonts.light.family,
      fontWeight: fonts.light.weight,
      fontSize: 24,
      marginBottom: 20,
    },
    [`& .${classes.subline}`]: {
      marginBottom: 20,
    },
  };
});

export const NoMatch: React.FC = () => {
  return (
    <NoMatchStyles>
      <Grid container>
        <Grid item xs>
          <Card variant="outlined">
            <CardContent>
              <Grid container direction="column" alignItems="center">
                <Typography
                  variant="h1"
                  align="center"
                  component="h1"
                  className={classes.headline}
                >
                  Seite kann nicht angezeigt werden
                </Typography>
                <Typography
                  align="center"
                  className={classes.subline}
                  gutterBottom
                >
                  Die von Ihnen gewünschte Seite existiert nicht oder Sie
                  besitzen nicht die notwendige Berechtigung.
                </Typography>
                <Button
                  variant="contained"
                  component={Link}
                  to={ROUTE_INDEX}
                  color="primary"
                  endIcon={<ChevronCircle />}
                >
                  Zurück zur Anwendung
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </NoMatchStyles>
  );
};
