import App from "./App";
import "./index.css";
import { createRoot } from "react-dom/client";

async function main() {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(<App />);
}

main();
