import type { GlobalState } from "./GlobalSlice";
import { setValue } from "./GlobalSlice";
import { useAppDispatch, useAppSelector } from "./RootState";

export function useReduxGlobal<K extends keyof GlobalState>(key: K) {
  const value = useGlobalValue(key);
  const setter = useGlobalValueSetter(key);
  return [value, setter] as const;
}
export function useGlobalValue<K extends keyof GlobalState>(
  key: K,
): GlobalState[K] {
  return useAppSelector((root) => root.global[key]);
}

export function useGlobalValueSetter<K extends keyof GlobalState>(
  key: K,
): (val: GlobalState[K]) => void {
  const dispatch = useAppDispatch();
  return (val: GlobalState[K]) => dispatch(setValue({ key, value: val }));
}
