import { RouteObject } from "react-router-dom";
import { Permission } from "../auth";

export function toHandle(
  title: string | ((params: Record<string, string>) => string),
  hasPermission?: Permission,
  isNavItem: boolean = false,
  featureFlag?: string
) {
  return { title, isNavItem, hasPermission, featureFlag };
}

export interface NavItem {
  path: string;
  title: string;
  hasPermission?: Permission;
  featureFlag?: string;
}

export function toNavItems(
  routes: RouteObject[],
  pathPrefix = "",
  parentPermission?: Permission
): NavItem[] {
  return routes.flatMap<NavItem>((r) => {
    const permission: Permission | undefined =
      r.handle?.hasPermission ?? parentPermission;

    const childNavItems = r.children
      ? toNavItems(r.children, pathPrefix + r.path, permission)
      : [];

    return r.handle?.isNavItem
      ? [
          {
            path: (pathPrefix + "/" + r.path).replace("//", "/"),
            title: r.handle.title,
            hasPermission: permission,
            featureFlag: r.handle.featureFlag,
          },
          ...childNavItems,
        ]
      : childNavItems;
  });
}
