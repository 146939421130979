import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { portalApi } from "~/api";
import { globalReducer } from "./GlobalSlice";

export function createStore(preloadedState?: object) {
  const store = configureStore({
    reducer: {
      global: globalReducer,
      // Add the generated reducer as a specific top-level slice
      [portalApi.reducerPath]: portalApi.reducer,
    },
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(portalApi.middleware),
  });

  // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
  // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
  setupListeners(store.dispatch);
  return store;
}
